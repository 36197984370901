.trending-repos-container__grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
    justify-items: center;
    width: 100%;
}

@media (min-width: 481px) {
    .trending-repos-container__grid {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
}
