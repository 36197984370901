.menu__item {
    padding: 1.2rem 1.5rem;
    background-color: transparent;
    border: 0;
    border-top: 1px solid #ccc;
    cursor: pointer;
    color: #24292f;
    font-size: 1.5rem;
    font-weight: 500;
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
}

.menu__item:hover {
    color: #fff;
    background-color: blue;
}
